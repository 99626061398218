
import { DragOutlined, DeleteOutlined,CopyOutlined } from '@ant-design/icons-vue';
import FieldRender from '../../components/FormRender/FieldRender.vue';
import FromRender from '../../components/FormRender/FromRender.vue';
import FieldSetting from './component/FieldSetting.vue';
import FormSetting from './component/FormSetting.vue';
import { defaultConfig, defaultValue, defaultPlaceholder, defaultOptions } from './defaultConfig';
import { loadOptions } from './utils';
import { message } from 'ant-design-vue';
import {
  batch_create_form_item,
  get_form_item_by_group,
  set_default_value_by_form_group, update_page,
} from '@/api/check_form-model';
import { useRoute } from 'vue-router';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { useForm } from 'ant-design-vue/es/form';
import modalBox from '@/components/form-modal/modal-tools';

export default defineComponent({
  name: 'FormDesign1',
  props: {
    showPreview: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const route = useRoute();
    // route.meta.title = route.params['name'];
    const org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const page_id = route.params['page_id'];
    const renderRef = ref();
    const formRef = ref();
    const designBodyRef = ref();
    const formItemRefDict: any = ref({});
    onMounted(() => {});
    const search = reactive({
      current: 1,
      pageSize: 2000,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        org_id: org?.id,
        is_active: true,
      },
    });
    const formGroup = reactive({
      title: '表单分组',
      column_flag: 'formgroup',
      search: {
        ...search,
        requestParams: { ...search.requestParams, page_id: page_id },
        addParams: { page_id: page_id },
      },
      modalType: 'check_form-formgroup',
      mode: 'radio',
      is_show_all_column: false,
      is_show_action: false,
      is_show_excel: false,
      is_show_refresh: false,
      is_show_change_column: false,
      is_show_fullscreen: false,
    });

    const state: any = reactive({
      group_id: null,
      defaultConfig,
      defaultValue,
      defaultPlaceholder,
      defaultOptions,
      visible: false,
      activeKey: 'formSetting',
      form: {
        settings: {
          name: '表单名称',
          labelAlign: 'right',
          layout: 'vertical',
          labelCol: {
            span: 24,
          },
          wrapperCol: {
            span: 24,
          },
        },
        fields: [],
        rules: reactive({}) as any,
        model: {} as any,
        validateInfos: {} as any,
      },
      options: {} as any,
      field: {
        label: '',
        name: '',
      },
    });

    const form_options = computed(() => {
      const options: any = {};
      state.form.fields.map((item: any) => {
        options[item.name] = item.options;
      });
      return options;
    });

    const get_form_by_group = (record: any) => {
      state.group_id = record.id;
      get_form_item_by_group({
        group_id: record.id,
        page_id: page_id,
        org_id: org?.id,
        is_active: true,
      }).then((res: any) => {
        state.form.fields.length = 0;
        state.form.settings.name = res.name;
        state.form.settings.layout = 'vertical';
        state.form.settings.labelCol= {
          span:24,
        };
        state.form.settings.wrapperCol= {
          span: 24,
        };
        state.form.settings.is_times = res.is_times;
        state.form.settings.times = res.times;
        state.form.settings.times_list = res.page__times.map((item:any)=>{
          return { label:item+'点', value:item };
        });
        res.item_list.map((item: any) => {
          let temp: any = {
            label: item.name,
            name: item.flag,
            extra: item.desc,
            type: item.field_type,
            is_required: item.is_required,
            mode: item.is_single?'default':'multiple',
            warning_rules: item.warning_rules,
            warning_selected: item.warning_selected,

          };
          if (item.is_choices) {
            temp.options = item.choices_list;
            temp.warning_select = [];
          }
          state.form.fields.push(temp);
          state.form.model[item.flag] = null;
        });
        Object.assign(state.form.model, res.default_value);
        // state.form.model = res.default_value;
        const { validateInfos } = useForm(state.form.model, state.form.rules);
        state.form.validateInfos = validateInfos;
      });
    };
    watch(
      () => state.field,
      newValue => {
        if (newValue.name) {
          state.activeKey = 'fieldSetting';
        } else {
          state.activeKey = 'formSetting';
        }
      },
    );
    watch(
      () => state.field.label,
      () => {
        changeClaceholder();
      },
    );
    onMounted(() => {
      state.form.fields.forEach((field: any) => {
        state.form.rules[field.name] = [];
        loadOptions(state.form.options, field);
      });
    });
    const dragstart = (e: any, data: any) => {
      e.dataTransfer.setData('data', JSON.stringify(data));
    };
    const drop = (e: any, index: any) => {
      e.stopPropagation();
      let data = JSON.parse(e.dataTransfer.getData('data'));
      if (data.type === 'sort') {
        let temp = state.form.fields[data.index];
        state.form.fields.splice(data.index, 1);
        state.form.fields.splice(index, 0, temp);
      } else {
        let field = JSON.parse(JSON.stringify(state.defaultConfig[data.fieldType]));
        if (field.name) {
          field.name = field.name + '_' + new Date().getTime();
        }
        field.defaultValue = state.defaultValue[field.type];
        field.validateInfos = {};
        field.options = field.datasource ? [...state.defaultOptions[field.datasource]] : [];
        field.extra = '';
        field.mode = 'default';
        field.warning_select = [];
        field.is_required = false;
        state.form.fields.push(field);
        state.field = field;
        changeClaceholder();
        removeSelected();
        designBodyRef.value.scrollTop = designBodyRef.value.scrollHeight;
      }
    };
    const ok = () => {
      renderRef.value
        .validate()
        .then((e: any) => {
          console.log(e);
        })
        .catch((error: any) => {
          console.log('error', error);
        });
    };
    const reset = () => {
      renderRef.value.reset();
    };
    const click = (field: any) => {
      state.field = field;
      removeSelected();
      formItemRefDict.value[field.name].classList.add('selected');
    };
    const removeSelected = () => {
      document.querySelectorAll('.design-item.selected').forEach(item => {
        item.classList.remove('selected');
      });
    };
    const changeClaceholder = () => {
      if (state.defaultPlaceholder[state.field.type]) {
        state.field.placeholder = state.defaultPlaceholder[state.field.type] + state.field.label;
      }
    };
    const save = () => {
      let is_ok = true;
      for (let i = 0, len = state.form.fields.length; i < len; i++) {
        const f = state.form.fields.find((obj: any, index: number) => {
          return i !== index && state.form.fields[i].name === obj.name;
        });
        if (f) {
          message.success(
            `【${state.form.fields[i].label}】与【${f.label}】存在相同的${f.name}字段名称，请检查修改`,
          );
          is_ok = false;
          break;
        }
      }
      if (!is_ok) {
        return;
      }
      let list: any[] = [];
      state.form.fields.map((item: any, index: any) => {
        list.push({
          name: item.label,
          flag: item.name,
          desc: item.extra,
          is_required: item.is_required,
          field_type: item.type,
          is_choices: item.options ? item.options.length > 0 : false,
          choices_list: item.options,
          is_single: item.type=='checkbox'?false:item.mode !== 'multiple',
          warning_selected: item.warning_selected,
          warning_rules: item.warning_rules,
          sorted: index,
        });
      });
      const model: any = {};
      state.form.fields.forEach((field: any) => {
        model[field.name] = state.form.model[field.name];
      });
      batch_create_form_item({
        org_id: org?.id,
        page_id: page_id,
        group_id: state.group_id,
        group_data:{ is_times: state.form.settings.is_times, times:state.form.settings.times },
        default_value: JSON.stringify(model),
        form_item_list: list,
      }).then(() => {
        message.success('保存表单成功');

      });
    };
    const clear = () => {
      state.form.fields = [];
    };
    const remove = (e: any, index: any) => {
      e.stopPropagation();
      let field = state.form.fields[index];
      state.form.fields.splice(index, 1);
      state.field = {};
      delete state.form.model[field.name];
      delete state.form.rules[field.name];
    };

    const copy = (e: any, index: any) => {
      e.stopPropagation();
      let field = state.form.fields[index];
      const new_field:any = JSON.parse(JSON.stringify(field));
      new_field.name = new_field.type + '_' + new Date().getTime();
      state.form.fields.push(new_field);
      if(state.form.model[field.name]){
        state.form.model[new_field.name] = JSON.parse(JSON.stringify(state.form.model[field.name]));
      }
      if(state.form.rules[field.name]){
        state.form.rules[new_field.name] = JSON.parse(JSON.stringify(state.form.rules[field.name]));
      }
    };

    const getSettings = () => {
      return state.form;
    };

    const addOptions = () => {
      if (state.field.options == undefined) {
        if ((defaultConfig as any)[state.field?.type as string]?.datasource != undefined) {
          state.field.options = [];
        }
      }
      state.field.options.push({ label: '' });
    };
    const removeOptions = (index: number) => {
      state.field.options = state.field.options.filter((item: any, i: number) => index !== i);
    };
    const changeOptions = (index: number) => {
      state.field.options[index]['name'] = state.field.options[index]['label'];
      state.field.options[index]['value'] = state.field.options[index]['label'];
    };


    const addWarning = () => {
      const value = ref(false);
      const range_value = ref(false);
      const editModal = reactive({
        visible: true,
        loading: false,
        title: '新建点检表',
        form:{
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [{
            type: 'radio',
            name: 'warning_type',
            label: '规则类型',
            disabled: false,
            placeholder: '请选择区分班别',
            default: true,
            allowClear: true,
          },{
            type: 'number',
            name: 'value',
            label: '阈值',
            disabled: value,
            placeholder: '请填写阈值',
            allowClear: true,
          },{
            type: 'number',
            name: 'min_value',
            label: '最小阈值',
            disabled: range_value,
            placeholder: '请填写阈值',
            allowClear: true,
          },{
            type: 'number',
            name: 'max_value',
            label: '最大阈值',
            disabled: range_value,
            placeholder: '请填写阈值',
            allowClear: true,
          },

          ],
          model: {
            warning_type: 'max',
          },
          watch:{
            warning_type:(model:any)=>{
              if(model.warning_type=='min'||model.warning_type=='max'){
                value.value = false;
                range_value.value = true;
              }else{
                value.value = true;
                range_value.value = false;
              }
            },
          },
        },
        options: {
          warning_type: [{ label: '低于某个值警告', value: 'min' }, { label: '高于某个值警告', value: 'max' }, { label: '范围内警告', value: 'in' }, { label: '范围外警告', value: 'out' }],
        },


        ok: (data:any) => {
          const p = new Promise(function (resolve) {
            editModal.loading = true;
            if(state.field.warning_rules==undefined){
              state.field.warning_rules = [];
            }
            if(data.warning_type=='min'){
              state.field.warning_rules.push({ title: `低于${data.value}的值是报警值`, warning_type:data.warning_type, value:data.value });
            }else if(data.warning_type=='max'){
              state.field.warning_rules.push({ title: `高于${data.value}的值是报警值`, warning_type:data.warning_type, value:data.value });
            }else if(data.warning_type=='in'){
              state.field.warning_rules.push({ title: `高于${data.min_value}的值,并且低于${data.max_value}的值是报警值`, warning_type:data.warning_type, min_value:data.min_value, max_value:data.max_value });
            }else if(data.warning_type=='out'){
              state.field.warning_rules.push({ title: `低于${data.min_value}的值，或者高于${data.max_value}的值是报警值`, warning_type:data.warning_type, min_value:data.min_value, max_value:data.max_value });
            }
            resolve(null);
            editModal.loading = false;
          });
          return p;
        },
      });
      modalBox(editModal);

    };
    const removeWarning = (index: number) => {
      state.field.warning_rules = state.field.warning_rules.filter((item: any, i: number) => index !== i);
    };

    return {
      state,
      form_options,
      formGroup,
      get_form_by_group,
      renderRef,
      formRef,
      formItemRefDict,
      designBodyRef,
      dragstart,
      drop,
      ok,
      reset,
      click,
      removeSelected,
      changeClaceholder,
      save,
      clear,
      remove,
      copy,
      getSettings,
      addWarning,
      removeWarning,
      addOptions,
      removeOptions,
      changeOptions,
    };
  },
  components: { FieldRender, FromRender, FormSetting, FieldSetting, CopyOutlined, DeleteOutlined },
});
